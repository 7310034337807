export const isMobile = () => {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    .test(window.navigator.userAgent)
}

export const isSafari = () => {
  return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
}

export const isWeChatBrowser = () => { //包含PC版微信浏览器
  //MicroMessenger/7.0.20.1781(0x6700143B) WindowsWechat
  //MicroMessenger/8.0.20(0x1800142e) NetType/WIFI Language/zh_CN miniProgram
  return /MicroMessenger/i.test(window.navigator.userAgent) && !/miniProgram/i.test(window.navigator.userAgent)
}
